.checkout-wrap {
	padding: 30px 50px;
	background-color: #fff;
	border-radius: 5px;
	overflow: hidden;
  }
  
  #checkout-bar {
	position: relative;
	width: 100%;
	padding: 0;
	list-style: none;
	float: left;
	clear: both;
  }
  
  #checkout-bar li {
    position: relative;
    width: 34%;
    float: left;
    text-align: center;
    color: #999;
    text-transform: uppercase;
}
  
  #checkout-bar li:after {
    position: relative;
    content: "";
    display: inline-block;
    width: 29px;
    height: 29px;
    top: 5.5px;
    left: 50%;
    border: 2px solid #ddd;
    background-color: #fff;
    border-radius: 100%;
    z-index: 4;
}
  
  #checkout-bar.step1 .step1:after,
  #checkout-bar.step2 .step2:after,
  #checkout-bar.step3 .step3:after,
  #checkout-bar.step4 .step4:after {
	background-color: #a76a6a;
	-webkit-transition: width 2s;
	/* Safari */
	transition: all 3s;
  }
  
  #checkout-bar li span {
    position: relative;
    display: block;
    left: 50%;
    width: 150PX;
    margin: auto;
}
  
  #checkout-bar:before,
  #checkout-bar:after {
	position: absolute;
	content: "";
	top: 50%;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	margin: auto 0;
	background-color: #c3c3c3;
	z-index: 1;
  }
  #checkout-bar.step1:after, #checkout-bar.step2:after, #checkout-bar.step3:after, #checkout-bar.step4:after {
    width: 34%;
    background-color: #a76a6a;
    z-index: 2;
    transition: width 2s;
}

   #checkout-bar.step2:after {
    width: 69%;
}
  #checkout-bar.step3:after {
	width: 75%;
  }
  
  #checkout-bar.step4:after {
	width: 100%;
  }
  
  button {
	border: none;
	background: none;
  }
  
  .btn {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
  }
  
  .btn-success {
	display: block;
	min-width: 220px;
	margin: 0 auto;
	text-transform: uppercase;
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c;
  }
  
  .btn-success:hover {
	color: #fff;
	background-color: #449d44;
	border-color: #398439;
  }
  
  .btn.focus,
  .btn:focus,
  .btn:hover {
	color: #fff;
	text-decoration: none;
  }
  
  .space {
	width: 100%;
	float: left;
	clear: both;
	height: 50px;
  }
  h2.accordion-header{
	font-size: 16px;
	padding: 10px;
  }